<template>
  <div class="container p-body mt-4">
    <h5>Privacy Policy GDPR Legislation</h5>
    <p>
      The EU introduced new Data Protection legislation from May 25th 2018. We
      have audited all of our processes, data handling and retention protocols
      and where appropriate have made amendments to them, to ensure we are fully
      compliant with the GDPR. We regularly review our processes, to identify
      any further areas where improvements can be made, so we can make any
      necessary changes. Estia Tuition offers services via this website or its
      associated websites and such educational programs, services and delivery
      mechanisms will be collectively referred to as the Estia Learning Systems
      (ELS). Estia Tuition publishes its privacy policy to give our customers a
      better understanding of our privacy policy and practice. If you have any
      questions, please contact
      <a href="mailto:admin@estiatuition.com">admin@estiatuition.com</a>. Estia
      Tuition is committed to respecting your online privacy and to recognising
      your need for appropriate protection and management of any personally
      identifiable information (personal information) you share with us. We have
      established this online privacy policy so that you can understand the care
      with which we intend to treat your personal information. Personal
      information means any information that may be used to identify an
      individual, including, but not limited to, a first and family name, a home
      or other physical address, an email address or other contact information,
      whether at work or at home. In general, you can visit Estia Tuition's
      webpages without telling us who you are or revealing any personal
      information about yourself. Estia Tuition strives to comply with all
      applicable laws around the globe that are designed to protect your
      privacy. Although legal requirements may vary from country to country,
      Estia Tuition intends to adhere to the principles set forth in this online
      privacy policy and our goal is to provide protection for your personal
      information, no matter where that personal information is collected,
      transferred or retained. To protect your privacy, we have adopted the
      following principles:
    </p>
    <ul>
      <li>
        We never buy children's data of any type from schools or anyone. Ever.
      </li>
      <li>
        The only data we receive is provided by parents, from online or paper
        forms you may have filled out and sent to us, or when enrolling your
        children on the programme;
      </li>
      <li>
        We will never sell on, share or give away any data you have given to us,
        to anyone else outside the Estia Tuition Group and specific contracted
        third-parties who provide services available on the ELS System on Estia
        Tuition's behalf.
      </li>
      <li>
        We will always ask for and gain your agreement to use your data for
        communicating with you, for providing educational support to your
        children and we only ask for the data we reasonably need to do that
      </li>
      <li>
        If you wish to decline an invitation from us, we do not ask for any
        family or children's details, just a simple “No Thank You”
      </li>
      <li>
        If you ask us not to contact you at a number or email you've given us,
        we won't.
      </li>
    </ul>
    <p>This privacy policy explains the following:</p>
    <ol>
      <li>What information Estia Tuition may collect about you;</li>
      <li>How Estia Tuition will use information we collect about you;</li>
      <li>How Estia Tuition will keep your data secure;</li>
      <li>Estia Tuition's cookie use</li>
      <li>Whether Estia Tuition will disclose your details to anyone else;</li>
      <li>
        How Estia Tuition will use your information to act on offensive or
        inappropriate content
      </li>
      <li>How Estia Tuition will treat children aged 16 or less</li>
      <li>
        Your choices regarding the personal information you have provided to us.
      </li>
    </ol>
    <p>
      It also explains our policy with respect to system passwords and our
      policy to ensure child security on our website. Protecting you and your
      family's privacy online is an evolving area, and Estia Tuition's websites
      are constantly evolving to meet these demands
    </p>
    <h5>1. What information will Estia Tuition collect about me?</h5>
    <p>
      To enable us to operate our services for you and your family, we need to
      hold both personal and educational data. We seek to ensure that we only
      hold the data that is necessary for the provision of our services and to
      be able to communicate with you in the way(s) that you have agreed to. You
      may choose whether or not to provide personal information to Estia
      Tuition. If you choose not to provide the personal information we request,
      you can still visit most of Estia Tuition's websites, but you may be
      unable to access certain options, offers and services that involve our
      interaction with you. We never buy personal information from schools or
      other suppliers. Ever. Any data we hold will have been provided to us by
      parents, who will have either
    </p>
    <ul>
      <li>
        Filled in an online or paper form to make an enquiry, either in response
        to an advertising activity or directly from our own website; or
      </li>
      <li>
        Provided us with data, using either an online or paper form, or in
        writing via a letter, or over the telephone as part of an enquiry or
        service call, or in a face to face discussion with a member of our staff
      </li>
    </ul>
    <p>
      When you fill in an online or paper enquiry form, or sign up to buy a
      programme or participate in or receive a service from Estia Tuition or
      from any other part of the websites, such as newsletters or competitions
      for example, we may ask for personal information about you. This may
      consist of information such as your name, e-mail address, postal address,
      telephone or mobile phone number and such additional information about
      your family as is needed for the provision of that programme. Different
      services and web pages may need different personal information. By
      entering your details in the fields requested, you enable Estia Tuition to
      provide you with the services you select. Please note that on particular
      web pages we will sometimes require you to provide additional personal
      information, such as your Estia Tuition Registration Number. When we do
      this, if it not reasonably obvious why, we will specify further why we are
      collecting your information and how we will use it.
    </p>
    <h5>2. How will Estia Tuition use the information it collects about me?</h5>
    <p>Estia Tuition will only use personal information for 4 purposes:</p>
    <ol>
      <li>
        To respond to an enquiry that you have made to us - either directly via
        our own website or through a third party contracted by us - and to send
        you information relevant to and derived from that enquiry
      </li>
      <li>
        To enable us to provide products, services and programmes to you and
        your children, that you have purchased or subscribed for. This includes
        using personal information to identify you and your children if logging
        in to one of our websites to use services and to inform you of
        information relevant to that service such as results, upgrades and
        updates to the services, instructions and technical support provision
        and similar services
      </li>
      <li>
        To carry out normal customer communication such as correspondence about
        your account, customer service calls, in relation to any complaint or
        contribution you may have made, to invite you to participate in surveys
        about Estia Tuition products and customer service (participation is
        always voluntary) or information on other relevant matters
      </li>
    </ol>
    <p>
      To the extent that you do provide us with personal information, Estia
      Tuition wishes to maintain accurate personal information. Where we collect
      personal information from you on the web, our goal is to provide a means
      of contacting Estia Tuition should you need to update or correct that
      information. If you ask us to stop communicating with you at any phone
      number or email address you have previously given us, we will. Please
      email a request to
      <a href="mailto:admin@estiatuition.com">admin@estiatuition.com</a> at any
      time. In such events, we may advise you of certain services that we will
      no longer be able to provide without using that contact information, e.g.
      where an email or phone number is essential to that provision, such as the
      sending of test results or alerts about actions required, in order that
      you can provide an alternative contact point, or, if you wish, we will
      cease to use that contact information anyway.<br />
      If for any reason those means are unavailable or inaccessible, you may
      send updates and corrections about your personal information to
      <a href="mailto:admin@estiatuition.com">admin@estiatuition.com</a> and we
      will make reasonable efforts to incorporate the changes in your personal
      information that we hold as soon as practicable.
    </p>
    <h5>3. How will Estia Tuition keep my data secure?</h5>
    <p>
      Wherever your personal information may be held within Estia Tuition or on
      its behalf, we will take reasonable and appropriate steps to protect the
      personal information that you share with us from unauthorised access or
      disclosure. All personal information is held in a secure environment. Our
      systems are all hosted in the UK or the EU, by some of the largest
      professional hosting companies in the world and have the latest security
      protection available. All databases conform to the highest UK Data
      Protection Standards and the latest UK data protection legislation and we
      are registered with the Information Commissioner's Office to hold and
      process personal data. Access to data within Estia Tuition is strictly
      controlled and managed, using passwords and access level management
      software, to ensure that our staff members only see the data they need to
      see to do their jobs and meet our customer's needs and requests.
    </p>
    <h5>4. Cookies and other tracking technologies</h5>
    <p>
      Some of our webpages utilise 'cookies' and other tracking technologies. A
      cookie is a small text file that may be used, for example, to collect
      information about website activity. Some cookies and other technologies
      may serve to recall personal information previously indicated by a web
      user. Most browsers allow you to control cookies, including whether or not
      to accept them and how to remove them. You may set most browsers to notify
      you if you receive a cookie or you may choose to block cookies with your
      browser. Please note if you choose to erase or block your cookies, you
      will be required to re-enter your original user ID and password to gain
      access to certain parts of the website. Tracking technologies may record
      information such as internet domain and host names, internet protocol (IP)
      addresses, browser software and operating system types, click-stream
      patterns, and dates and times that our site is accessed. Our use of
      cookies and other tracking technologies allows us to improve our website
      and your web experience. We may also analyse information that does not
      contain personal information for trends and statistics. This information
      is not used to develop a personal profile of you and the log files are
      regularly purged.
    </p>
    <h5>
      5. Will Estia Tuition share my personal information with anyone else?
    </h5>
    <p>
      We never sell on, share, give away or otherwise pass personal data to any
      other organisation outside the Estia Tuition Group and specific contracted
      third-parties who provide services available on the ELS System on Estia
      Tuition's behalf. The only exception to this would be where disclosure is
      permitted by law and required by a designated authority, which is duly
      authorised to require us to do that under specific circumstances, for
      example to government authorities and law enforcement agencies Where we
      provide personal data to third-party service providers to help us deliver
      programs, products, information or services, we only provide it under the
      same confidentiality terms applied within Estia Tuition and solely and
      exclusively for the limited purpose of providing the specific services for
      which they have been contracted, not for any other use or purpose. Service
      providers are an important means by which Estia Tuition maintains its
      websites and communications capabilities . Estia Tuition will take
      reasonable steps to ensure that these third-party service providers are
      obligated to protect personal information on Estia Tuition's behalf.
    </p>
    <h5>6. Offensive or inappropriate content on Estia Tuition website</h5>
    <p>
      If you post or send offensive, inappropriate or objectionable content
      anywhere on or to estiatuition.com (or elsewhere on a website created by
      us), or otherwise engage in any disruptive behaviour on these specified
      websites (or elsewhere on a website created by us), Estia Tuition may use
      your personal information to stop such behaviour. Where Estia Tuition
      reasonably believes that you are or may be in breach of any of the laws of
      England or Wales (e.g. because content you have posted may be defamatory),
      Estia Tuition may use your personal information to inform relevant third
      parties such as your e-mail/Internet provider or law enforcement agencies
      about the content and your behaviour.
    </p>
    <h5>7. What if I am a user aged 16 or under?</h5>
    <p>
      We will never normally request personal information from a person under
      16, unless asked to do so by their parent, designated carer or other
      designated responsible adult. If you are aged 16 or under, please make
      sure that your parent or carer reads this information and get your
      parent/carer's permission beforehand whenever you provide personal
      information to Estia Tuition. Users under 16 years old without this
      consent must not provide us with personal (or related) information.
    </p>
    <h5>8. Your consent</h5>
    <p>
      By using this website, you consent to the terms of our online privacy
      policy and to Estia Tuition's processing of personal information for the
      purposes given above as well as those explained where Estia Tuition
      collects personal information on the web. Should the online privacy policy
      change, we will take every reasonable step to ensure that these changes
      are brought to your attention by posting all changes prominently on our
      website for a reasonable period of time. Passwords Estia Tuition supplies
      all students and parents with a unique user name and password for their
      accounts.
    </p>
    <ul>
      <li>
        Each student has his or her own personal user names and passwords that
        must be used to access their Estia Online Learning Systems (ELS).
      </li>
      <li>
        Passwords are not transferable and may only be used by the student or
        parent for whom they have been created. They should not be given to
        anyone else.
      </li>
      <li>
        If you lose or forget your username or password, our Customer Support
        Team at Estia Tuition can remind you of it, or reset it if you prefer -
        email a request to
        <a href="mailto:ess@estiatuition.com">ess@estiatuition.com</a> at any
        time.
      </li>
      <li>
        If someone else has been given your password and you want to change it,
        email a request to
        <a href="mailto:ess@estiatuition.com">ess@estiatuition.com</a> at any
        time and our Customer Support Team will be pleased to help you.
      </li>
    </ul>
    <h5>Child Security</h5>
    <p>
      At Estia Tuition we take your children's security very seriously and we
      have 8 simple but powerful principles to ensure this:
    </p>
    <ol>
      <li>
        We never use or record a child's e-mail address. We will only use and
        hold a family e-mail address controlled by and which and which has been
        given to us by, a parent or carer or other responsible adult a on
        registration with Estia Tuition, or later updated by a parent or carer
        or other responsible adult.
      </li>
      <li>
        When we communicate by e-mail it will only be to this family controlled
        e-mail box that you have nominated
      </li>
      <li>
        Every child has a unique user name and password for the Estia Tuition
        Learning system (EEL) and the Estia Support Services system (ESS),
        without which they cannot log in to their personal online learning or
        support spaces. These are not shared with any other member of the family
        or anyone else and will be provided to the family on or soon after
        registration.
      </li>
      <li>
        All children have both a personal online learning space and a personal
        online support space. Each is accessed using their unique username and
        password.
      </li>
      <li>
        Children can only access our Secure Interactive Whiteboard through their
        own personal online support space and no other children can access it at
        the same time as them. The only other person who can access it is the
        teacher who is tutoring the child and they must log in through the
        child's learning record to set up the session for that child.
      </li>
      <li>
        The teacher's name is always displayed to the children when they are on
        the Whiteboard and the child's name is always displayed to the teacher.
        In this way we always know which teacher is talking to a child at any
        time and can always refer back to see this at a later date.
      </li>
      <li>
        Teachers and other staff who have access to the children's learning
        information and learning records can only access these using a unique
        username and password of their own.
      </li>
      <li>
        Every teacher who works for Estia Tuition and tutors children is a fully
        Qualified UK Registered Teacher.
      </li>
    </ol>
    <h5>The Estia Tuition Contribution Terms</h5>
    <ol>
      <li>
        By forwarding us a contribution, you (or Estia Tuition where indicated)
        confirm the following:
      </li>
      <li>
        By sharing any contribution (including, but not limited to, any text,
        photographs, pictures, jokes or poems) with Estia Tuition you (and any
        relevant parent / carer) agree to grant Estia Tuition, free of charge,
        irrevocable permission to use the material, without time limit, in any
        way it wants (including modifying and adapting it for operational and
        editorial reasons and as explained below) for Estia Tuition in any
        media.
      </li>
      <li>
        Copyright in your contribution will remain with you and this permission
        is not exclusive, so you can continue to use the material in any way,
        including allowing others to use it.
      </li>
      <li>
        In order that Estia Tuition can use your contribution, you confirm that
        (if under 16) your parent or carer has agreed for you to send in your
        contribution with all other information and also agreed for you to enter
        any competition in which you are interested. You also confirm that your
        contribution is your own original work, is not defamatory and does not
        infringe any laws, that you have the right to give Estia Tuition
        permission to use it for the purposes specified above and that you have
        the consent of anyone who is identifiable in your contribution, or the
        consent of their parent / carer if they are under 16.
      </li>
      <li>
        We normally show (with your contribution) your name, age and city where
        you live, unless you request otherwise, but for operational reasons this
        is not always possible. Estia Tuition may need to contact you for
        administrative or verification purposes in relation to your
        contribution, or in relation to particular projects.
      </li>
      <li>
        Please do not endanger yourself or others, take any unnecessary risks or
        break any laws when creating content you may share with Estia Tuition.
      </li>
      <li>
        If you do not want to grant Estia Tuition the permission set out above
        on these terms, please do not submit to or share your contribution with
        Estia Tuition
      </li>
    </ol>
    <h5>
      Contacting Estia Tuition about this Privacy Policy and Contribution Terms
    </h5>
    <p>
      If you have any comments or questions regarding our online privacy policy,
      please contact us as follows:
    </p>
    <ul>
      <li>
        You can contact us by email at
        <a href="mailto:admin@estiatuition.com">admin@estiatuition.com</a>
      </li>
    </ul>
    <p>
      While we cannot guarantee privacy perfection, we will address any issue to
      the best of our abilities at our earliest opportunity.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  layout: "default",
  head: {
    title: "EstiaLabs - Privacy Policy",
  }
});
</script>
